@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  perspective: 1000px;
  /* background-image: url("https://drive.google.com/uc?id=1_pFiGeDeyeH8FdwlWkK2Lf2W0RYX7VQf"); */
  background-size: cover;
  background-position: center;
  display: "fixed";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
  z-index: 0;
  background-Clip: "content-box"; 
  transform: "translate3d(${translateX}px, ${translateY}px, 0)";
  transition: "transform 0.1s ease-out";
}
