@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Chewy&family=Luckiest+Guy&family=Poppins&display=swap');

h1 {
  font-family: 'Luckiest Guy', sans-serif;
  /* color: #591e62; */
  color: #9e43ad;
}

h2 {
  font-family: 'Chewy', sans-serif;
}

h3,h4 {
  font-family: 'Staatliches', sans-serif;
}

h5 {
  color: black;
  letter-spacing: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bolder;
}

hp{
  color: #505050;
}

p {
  font-family: 'Poppins', sans-serif;
}
#curbing-buttons {
  background-color: #dec6e2;
  background-image: linear-gradient(0deg, #dec6e2 0%, #591e62 100%);
}

.button-title {
  font-weight: bold;
  color: #000;
}


  .fade-in-out {
    position: relative;
    width: 32px;
    height: 32px;
    border: 1px solid #000;
    border-radius: 50%;
    overflow: hidden;
  }

  .fade-in-out img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .fade-in-out img.fade-in {
    opacity: 1;
    animation-name: fade-in;
  }

  .fade-in-out img.fade-out {
    opacity: 0;
    animation-name: fade-out;
  }

  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
  }
